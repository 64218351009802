class BuscaController {

	static instancias_de_busca = [];
	
	constructor(configs){
		this.configs = configs;
		this.payloadBusca = null;
		this.inputs = [
			'aca',
			'tip',
			'cid',
			'bai',
			'dor',
			'vag',
			'min',
			'max',
			'cod',
			'inf',
			'ord',
			'pag'
		];
		this.production = false;
	}

	trocaTipos(elementoTipo, valor_elemento){
		
		if(typeof(valor_elemento) !== 'undefined'){
			elementoTipo.elemento.data = [];
			
			for(let i = 0; i < valor_elemento.length; i++){
				
				elementoTipo.elemento.data[valor_elemento[i]] = elementoTipo.values[valor_elemento[i]];
			}
		}

		elementoTipo.elemento.render(true);

	};

	render(){

		if (typeof (Busca) !== 'undefined') {
			
			this.payloadBusca = new Busca(this.inputs, this.configs['render_busca_id'], this.production);
			for(var i in this.configs['elementos']){

				if(typeof(this.configs['elementos'][i]['input']) !== 'undefined'){
					let elementoInput = document.getElementById(this.configs['elementos'][i]['input']);

					this.configs['elementos'][i]['elemento'] = elementoInput;
					this.payloadBusca.addComponentsinputs(elementoInput);
					
					elementoInput.addEventListener('keyup', function(evento){
						
						let elementoSelecionado = evento.target;
						for(var instanciaBusca in BuscaController.instancias_de_busca){

							for(var x in BuscaController.instancias_de_busca[instanciaBusca]['buscaController']['configs']['elementos']){
								Boss.delayPersistent2(function(){
									BuscaController.instancias_de_busca[instanciaBusca]['busca'].add(elementoSelecionado.value, elementoSelecionado.id);
									BuscaController.instancias_de_busca[instanciaBusca]['busca'].send(false,false,0);
								}, 1000, elementoSelecionado.id);
							}
						}
					})

					continue;
				}

				this.configs['elementos'][i]['elemento'] = new Selectmultiple({
					'element': this.configs['elementos'][i]['elemento_id'],
					'alias': this.configs['elementos'][i]['alias'],
					'undo': this.configs['elementos'][i]['undo'],
					'ok': this.configs['elementos'][i]['ok'],
					'all': this.configs['elementos'][i]['all'],
					'search': this.configs['elementos'][i]['search'] ?? '',
					'callChange': function (e) {

						for(var instanciaBusca in BuscaController.instancias_de_busca){
							
							let elementoSelecionado = e.target.parentElement.parentElement.parentElement;

							for(var x in BuscaController.instancias_de_busca[instanciaBusca]['buscaController']['configs']['elementos']){

								let elementoInstancia = BuscaController.instancias_de_busca[instanciaBusca]['buscaController']['configs']['elementos'][x]['elemento'];
								if(elementoInstancia['elemento'] == elementoSelecionado){
									
									if(typeof(BuscaController.instancias_de_busca[instanciaBusca]['buscaController']['configs']['elementos'][x]['callChange']) == 'function'){
										BuscaController.instancias_de_busca[instanciaBusca]['buscaController']['configs']['elementos'][x]['callChange']();
									}

									if(typeof(elementoInstancia['alias']) !== 'undefined' && elementoInstancia['alias'] == 'aca'){
										let elementoTipo = BuscaController.instancias_de_busca[instanciaBusca]['buscaController']['configs']['elementos'].filter(elemento => elemento.alias == 'tip')[0];
										BuscaController.instancias_de_busca[instanciaBusca]['buscaController'].trocaTipos(elementoTipo, elementoInstancia.getValues);
									}

									BuscaController.instancias_de_busca[instanciaBusca]['busca'].add(elementoInstancia.getValues, elementoInstancia.alias);
									BuscaController.instancias_de_busca[instanciaBusca]['busca'].send(false, false, 0);

									
								}
							}
						}
					}
				}, this.configs['elementos'][i]['values']);
			}
			
			BuscaController.instancias_de_busca.push({'busca': this.payloadBusca, 'buscaController': this});

		}
	}

}

/* 
	document.addEventListener('DOMContentLoaded', evts => {
		if (typeof (Selectmultiple) !== 'undefined') {
			compMultiple();
		}

		if (typeof (Busca) !== 'undefined') {
			payloadBusca = new Busca(inputs, 'render-busca');

			payloadBusca.addSelectmultiple(select_tipoimovel);
			payloadBusca.addSelectmultiple(select_bairro);
			payloadBusca.addComponentsinputs(Boss.getById('cod'));
		}

	}, true); 
*/
window.openFormWppLead = (numero) => {
	var form_zap_interna = document.getElementById('form_zap_interna');
	form_zap_interna.classList.toggle('hidden');
	
	initValidate(numero);
};

window.closeFormWppLead = f => {
	var form_zap_interna = document.getElementById('form_zap_interna');
	form_zap_interna.classList.add('hidden');
};

window.initValidate = (numero) => {

	var fields = `{"whatsapp_nome": {
			"rules": {
				"empty": {"error": "Informe seu nome completo"}
			},
			"classError": "error-field"
		},
		"whatsapp_email": {
			"rules": {
				"empty": {"error": "Informe seu e-mail completo"},
				"email": {"error": "O e-mail informado é inválido!"}
			},
			"classError": "error-field"
		},
		"whatsapp_telefone": {
			"rules": {
				"empty": {"error": "Informe seu telefone completo"},
				"tel": {"error": "Número do celular inválido!"}
			},
			"mask": "tel",
			"classError": "error-field"
		}}
	`;
		
	Boss.validate.init({
		'config': {
			'formId': 'form-contato-whatsapp',
			'okMessage': 'Enviando...',
			'errorMessage': ' Tivemos um problema para enviar, tente novamente em alguns minutos.',
			'lockPushState': false
		},
		'fields': JSON.parse(fields),
		'send': function(){
			
			window.imo_codigo_zap = document.getElementById('imo_codigo_zap').value;
			var textwpp = encodeURIComponent(`Olá, tudo bem? Eu gostaria de mais informações sobre o imóvel ID: *${imo_codigo_zap}*.
			Ver imóvel no site: ${window.location.href}`);
			
			Boss.getById('xhrr').value = "xhr";

			if(window.screen.width >= 1024){
				window.open('//web.whatsapp.com/send?phone='+numero+'&text='+textwpp);
			}else{
				window.open('//api.whatsapp.com/send?phone='+numero+'&text='+textwpp);
			}

			var form_solicitar = Boss.getById('form-contato-whatsapp');
			form_solicitar.classList.add('hidden');

			var ux_env_feedback = Boss.getById('ux-env-feedbackk');
			ux_env_feedback.classList.remove('hidden');
			ux_env_feedback.innerHTML = '<i class="icl ic-spinner-third rotating"></i> Enviando...';

			Boss.ajax({
				'url': '/contato/enviarwppinterna',
				'formId': 'form-contato-whatsapp',
				'dataType': 'json',
				'done': function(rtn){
					
					if(rtn.r == 'ok'){

						ux_env_feedback.textContent = rtn.info;
						form_solicitar.style.display = 'none';

					}else {

						ux_env_feedback.textContent = rtn.info;
						form_solicitar.style.display = 'none';

					}
				},
				error: function(rtn){
					Boss.warning({message: 'Falha ao enviar, tente novamente em alguns segundos!'});
				}
			});
		},
		'fail': function(){
			Boss.getById('form-contato-whatsapp-interna').classList.remove('hidden');
			Boss.warning({message: 'Tivemos um problema para enviar, tente novamente em alguns minutos.'});
		}
	});
};

window.open_mensagem = tipo => {
	
	if(!mensagens_valores[tipo]){
		return;
	}

	var html = `
		<div class="bg-white" style="border-radius: 1rem;">
			<div style="padding: 2rem">
				<h1 class="strong text-center">${mensagens_valores[tipo]['nome']}</h1>
				<p>${mensagens_valores[tipo]['descricao']}</p>
			</div>
		</div>
	`;

	Boss.dialog.open({
		html: html,
		invisible: true,
		close: true
	});
};

window.openVideoInterna = (url_iframe) =>{
	var html = `
		<div class="dialogVideoInterna">
			<iframe src="${url_iframe}" allowfullscreen></iframe>
		</div>
	`;
		

	Boss.dialog.open({
		html: html,
		invisible: false,
		close: true,
	})
};

Boss.validate.init({
	'config': {
		'formId': 'form-contatointerna',
		'okMessage': 'Enviando...',
		'errorMessage': ' Tivemos um problema para enviar, tente novamente em alguns minutos.',
		'lockPushState': false
	},
	'fields': {
		'forma': {
			'rules': {
				'empty': {'error': 'Informe a forma de contato'}
			},
			'classError': 'error-field',
		},
		'nome': {
			'rules': {
				'empty': {'error': 'Informe seu nome completo'}
			},
			'classError': 'error-field',
		},
		'imo_codigo': {
			'classError': 'error-field',
		},
		'email': {
			'rules': {
				'email': {'error': 'O E-mail informado é inválido!'}
			},
			'classError': 'error-field',
		},
		'telefone': {
			'rules': {
				'empty': {'error': 'Informe seu telefone completo'},
				'tel': {'error': 'Número do celular inválido!'}
			},
			'mask': 'tel',
			'classError': 'error-field',
		},
		'politica': {
			'rules': {
				'empty': {'error': 'Aceite os termos para poder enviar o formulário'},
				'minSelection': {'error': 'Você deve concordar com a política de privacidade para prosseguir', 'parameters': {'minSelection': 1}}
			},
			'classError': 'error-field',
			'classOk': 'ok-field'
		}
	},
	'send': function(){
		
		var form_solicitar = Boss.getById('form-contatointerna');
		form_solicitar.classList.add('hidden');

		Boss.getById('button-submit').classList.add('hidden');

		var ux_env_feedback = Boss.getById('ux-env-feedback');
		ux_env_feedback.classList.remove('hidden');
		ux_env_feedback.innerHTML = '<i class="icl ic-spinner-third rotating"></i> Enviando...';

		Boss.getById('xhr').value = "xhr";

		Boss.ajax({
			'url': '/contato/enviarinterna',
			'formId': 'form-contatointerna',
			'dataType': 'json',
			'done': function(rtn){

				if(rtn.r == 'ok'){

					ux_env_feedback.textContent = rtn.info;
					form_solicitar.style.display = 'none';

				}else {
					ux_env_feedback.textContent = rtn.info;
					form_solicitar.style.display = 'none';
				}
			},
			error: function(rtn){
				Boss.warning({message: 'Falha ao enviar, tente novamente em alguns segundos!'});
			}
		});
	},
	'fail': function(){
		Boss.getById('form-contatointerna').classList.remove('hidden');
		Boss.getById('button-submit').classList.remove('hidden');
		Boss.warning({message: 'Tivemos um problema para enviar, tente novamente em alguns minutos.'});
	}
});

/*
	TUDO SOBRE A GALERIA NOVA ESTÁ AQUI ABAIXO 
*/
window.currentImage_mob = 0;

window.openGallery = f => {
	let gallery_fullscreen = document.getElementById('galeria_fullscreen');
	gallery_fullscreen.classList.remove('hidden');
	
	document.body.style.overflow = 'hidden';
};

window.closeGalleryFullscreen = f => {
	let gallery_fullscreen = document.getElementById('galeria_fullscreen');
	gallery_fullscreen.classList.add('hidden');

	document.body.style.overflow = 'auto';
};

window.currentImage = 0;
window.galeriaFullScreen = null;
window.imagesFS = typeof(galeria_imagens) !== 'undefined' ? galeria_imagens.children : [];

window.prevImage = f => changeImage('prev');

window.nextImage = f => changeImage('next');

window.changeImage = acao => {

	imagesFS = galeriaFullScreen ? imagens_gfs.children : galeria_imagens.children;

	for(let i = 0; i < imagesFS.length; i++){
		imagesFS[i].style.display = 'none';
	}
	
	let acaoTemp = acao == 'prev' ? currentImage - 1 : currentImage + 1;
	

	if(imagesFS[acaoTemp]){

		currentImage = acaoTemp;

	}else{
		
		currentImage = acao == 'prev' ? imagesFS.length - 1 : 0;

	}

	imagesFS[currentImage].style.display = 'block';
};

document.addEventListener('DOMContentLoaded', function(evts){

	let galeria_pc_paizao = document.getElementById('galeria_imagens');
	let elements_gallery = document.querySelectorAll('#galeria_imagens picture');
	for(let i = 0; i < elements_gallery.length; i++){
		if(i > 4){
			galeria_pc_paizao.removeChild(elements_gallery[i]);
		}
	}

	let count_images_gallery = document.querySelectorAll('#galeria_imagens picture').length;
	
	if(elements_gallery.length == 1){
		let galeriaNewStyle = document.getElementById('galeria_imagens');
		galeriaNewStyle.style.gridTemplateAreas = "img1";
		galeriaNewStyle.style.gridTemplateColumns = "1fr";

		document.getElementById("prevButton_mob").classList.add('hidden');
		document.getElementById("nextButton_mob").classList.add('hidden');

		document.getElementById("prevButton").classList.add('hidden');
		document.getElementById("nextButton").classList.add('hidden');
	}

}, true);
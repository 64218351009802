
class Selectmultiple {

	constructor(conf, data){

		this.rules(conf.element);

		this.idElemento = conf.element;

		this.conf = conf;

		this.values = new Object();

		this.elemento = document.getElementById(conf.element);

		this.label;

		this.undo;

		this.tagName = conf.name;

		this.alias = conf.alias;

		this.ok;

		/* ARMAZENA OS VALUES DO ESTADO ANTERIOR */
		this.estateVal;

		if(typeof(data) !== 'undefined'){

			this.data = data;

			for(let x in this.data){

				if(this.data[x].data && this.data[x].group){

					for(let y in this.data[x].data){

						if(this.data[x].data[y].checked && this.data[x].data[y].checked === true){

							this.values[this.data[x].data[y].value] = this.data[x].data[y].value;
						}
					}

				}else{

					if(this.data[x].checked && this.data[x].checked === true){

						this.values[this.data[x].value] = this.data[x].value;
					}
				}
			}

		}else{

			this.data = this.load(this.elemento);
		}

		this.render(true);

		this.init();
	}

	renderitem(data){
		
		var html = '';
		for(let x in data){
			if(data[x].data && data[x].group){
				html += '<div class="group">'+data[x].group+'</div>';
				html += '<div class="group_itens">'+this.renderitem(data[x].data)+'</div>';

			}else{

				var id = Selectmultiple._randId();

				var checked = '';
				if(this.values[data[x].value]){
					checked = 'checked="checked"';
				}

				html += '<div>\
					<div class="formsimple-checkbox formsimple-checkbox-min">\
						<input type="checkbox" '+checked+' id="'+id+'" name="'+this.conf.name+'" value="'+data[x].value+'">\
						<label for="'+id+'" title="'+data[x].label+'">\
							<span></span>'+data[x].label+'\
						</label>\
					</div>\
				</div>';

			}
		}

		return html;
	}

	render(render){

		let labelStr = 'Tudo';
		if(this.conf.all && this.conf.all !== ''){
			labelStr = this.conf.all; 
		}

		let search;
		let component;
		let items;
		let botoes;
		let undo;
		let ok;

		if(this.elemento.querySelector('.label')){
			this.label = this.elemento.querySelector('.label');
		}

		this.label.textContent = labelStr;

		if(this.elemento.querySelector('.component') === null){
			component = document.createElement('div');
			
			component.setAttribute('class', 'component');

			/* if(window.location.pathname === '/'){
				console.log('oi');
				component.setAttribute('class', 'component_home');

			}else{

				component.setAttribute('class', 'component');

			} */

			this.elemento.appendChild(component);
		}else{

			component = this.elemento.querySelector('.component');

		}

		if(this.conf && this.conf.search && this.conf.search !== false){

			var placeholder = this.conf.search.placeholder ? this.conf.search.placeholder : '';

			if(this.elemento.querySelector('.search') === null){
				search = document.createElement('div');
				search.setAttribute('class', 'search');
				search.innerHTML = '<input type="text" placeholder="'+placeholder+'" />';
				component.appendChild(search);
			}else{
				search = this.elemento.querySelector('.search');
			}
		}

		if(this.elemento.querySelector('.items') === null){
			items = document.createElement('div');
			items.setAttribute('class', 'items');
			component.appendChild(items);
		}else{
			items = this.elemento.querySelector('.items');
		}

		if(this.conf && (this.conf.undo !== false || this.conf.ok !== false)){

			if(this.elemento.querySelector('.botoes') === null){
				botoes = document.createElement('div');
				botoes.setAttribute('class', 'botoes');
				component.appendChild(botoes);
			}else{
				botoes = this.elemento.querySelector('.botoes');
			}
		}

		if(render === true){

			if(this.conf && this.conf.undo !== false){

				if(this.elemento.querySelector('.undo') === null){
					this.undo = document.createElement('button');
					this.undo.setAttribute('class', 'undo');
					this.undo.setAttribute('type', 'button');
					this.undo.setAttribute('tabindex', '-1');
					this.undo.textContent = this.conf.undo;
					botoes.appendChild(this.undo);
				}else{
					this.undo = this.elemento.querySelector('.undo');
				}

				Boss.evts.add('click', this.undo, evento => setTimeout(this.setValues([]), 170));
			}

			if(this.conf && this.conf.ok !== false){

				if(this.elemento.querySelector('.ok') === null){
					this.ok = document.createElement('button');
					this.ok.setAttribute('class', 'ok');
					this.ok.setAttribute('type', 'button');
					this.ok.setAttribute('tabindex', '-1');
					this.ok.textContent = this.conf.ok;
					botoes.appendChild(this.ok);
				}else{
					this.ok = this.elemento.querySelector('.ok');
				}

				Boss.evts.add('click', this.ok, evento => setTimeout(this.setChange(evento), 170));
			}
		}

		items.innerHTML = this.renderitem(this.data);

		this.setLabel();
	}

	load(elemento){

		let slct = elemento.querySelector('select');

		let opts = slct.querySelectorAll('option');

		let optsl = opts.length;

		let arr = new Array();
		for(let x = 0; x < optsl; x++){

			if(opts[x].checked && opts[x].checked === true){
				this.values[opts[x].value] = opts[x].value;
			}

			arr.push({
				'value': opts[x].value,
				'label': opts[x].textContent
			});
		}

		return arr;
	}

	static _randId(){

		return 'id_'+Math.random().toString(36).substring(2);
	}

	rules(id_elemento){

		if(typeof(id_elemento) === 'undefined'){
			console.warn('Você precisa informar o ID do elemento.');
			return false;
		}

		if(!Boss.getById(id_elemento)){
			console.warn('O elemento "' + id_elemento + '" não existe.');
			return false;
		}
	}

	setValue(v){

		let temp = new Object;
		temp[v] = v;

		this.values = temp;

		this.render(false);

		this.estateVal = this.getValues;
	}

	setValues(v){

		let temp = new Object;
		for(let x in v){
			temp[v[x]] = v[x];
		}

		this.values = temp;

		this.render(false);

		this.estateVal = this.getValues;
	}

	get getValues(){

		let inputs = this.elemento.querySelectorAll('input[type="checkbox"]');

		let listaValores = new Array();
		for(var i = 0; i < inputs.length; i++){
			
			if(inputs[i].checked === true){
				listaValores.push(inputs[i].value);
			}
		}

		return listaValores;
	}

	setFilters(){

		var search = this.elemento.querySelector('.search input[type="text"]');
		var labels = this.elemento.querySelectorAll('label');
		var groups = this.elemento.querySelectorAll('.group');

		var ele_groups = this.elemento.querySelectorAll('.group_itens');
		
		var l = labels.length;
		var g = groups.length;
		if(search){
			var exp = new RegExp(search.value, 'i');
			var found = 0;
	
			for(var x = 0; x < l; x++){
				var tx = labels[x].getAttribute('title');
				if(exp.test(tx) || search.value == ''){
					labels[x].parentElement.classList.remove('hidden');
					found = found + 1;
				}else{
					labels[x].parentElement.classList.add('hidden');
				}
			}

			for(var t = 0; t < g; t++){
				var elementos = ele_groups[t].childNodes;
				var contador = 0;

				for( var child = 0; child < elementos.length; child++){
					if(elementos[child].childNodes[1].classList.contains('hidden')){
						contador++;
					}
				}

				if(contador == elementos.length){
					groups[t].classList.add('hidden');
					ele_groups[t].classList.add('hidden');
				}else{
					groups[t].classList.remove('hidden');
					ele_groups[t].classList.remove('hidden');
				}
			}
		}

	}

	setLabel(){

		var values = this.getValues;

		let html = '';
		for(let x in this.data){

			if(this.data[x].data && this.data[x].group){

				for(let y in this.data[x].data){

					for(let z in values){

						if(this.data[x].data[y].value == values[z]){

							html += this.data[x].data[y].label+', ';
						}
					}
				}

			}else{

				for(let z in values){

					if(this.data[x].value == values[z]){

						html += this.data[x].label+', ';
					}
				}
			}
		}

		if(html != ''){

			this.label.textContent = html.substring(0, 13)+'...';

		}else{

			let labelStr = 'Tudo';
			if(this.conf.all && this.conf.all !== ''){
				labelStr = this.conf.all; 
			}

			this.label.textContent = labelStr;
		}
	}

	setChange(evento){

		if(this.conf.callChange){

			this.conf.callChange(evento);
		}

		/* ATUALIZA ESTADO */
		this.estateVal = this.getValues;

		this.setLabel();
	}

	setChecks(){

		this.setLabel();
	}

	init(){

		this.ft = new Focustoggle(this.idElemento);

		var callchange = document.createEvent('Event');
		callchange.initEvent('callchange', true, false);

		/* ARMAZENA ESTADO ANTERA PARA O BLUR + CALLCHANGE FUNCIONAR */
		this.estateVal = this.getValues;

		Boss.evts.add('callchange', this.elemento, evento => this.setChange(evento));
	}
}

class Focustoggle {

	constructor(id_elemento){

		this.elemento = Boss.getById(id_elemento);

		this.init();
	}

	static _abrir(elemento, evento){

		if(elemento.getAttribute('data-lastevt') == 'blur' && evento.type == 'focus'){
			if(elemento.querySelector('.component')){

				elemento.querySelector('.component').parentElement.classList.add('active');

				/* LÓGICA DE COLIZÃO, AQUI DEFINE SE VAI ABRIR PARA CIMA OU PARA BAIXO AS OPCOES. */
				/* É importante add a classe .active no .component ANTES dessa logica para saber o tamanho do elemento e fazer o calculo */
				let tamanho_do_componente_select = elemento.querySelector('.component').offsetHeight;
				let tamanho_elemento_com_componente = elemento.getBoundingClientRect().top + tamanho_do_componente_select + 60; /** Esse 60 é um espaço de margin para nao ficar tão colado na tela inferior. */
				elemento.querySelector('.component').style.top = null;
				if(tamanho_elemento_com_componente > window.innerHeight){
					elemento.querySelector('.component').style.top = '-'+tamanho_do_componente_select+'px';
				}

				elemento.setAttribute('data-lastevt', 'focus');
			}
		}
	}
	static _sair(elemento, evento){

		if(elemento.getAttribute('data-lastevt') == 'focus' && evento.type == 'blur'){
			if(elemento.querySelector('.component')){
				elemento.querySelector('.component').parentElement.classList.remove('active');
				elemento.setAttribute('data-lastevt', 'blur');

				Boss.trigger('callchange', elemento);
			}
		}
	}

	static _click(elemento, evento){

		var label = elemento.querySelector('.label');

		if(label === evento.target){

			if(elemento.getAttribute('data-lastevt') == 'blur' && evento.type == 'click'){

				elemento.focus();

			}else if(elemento.getAttribute('data-lastevt') == 'focus' && evento.type == 'click'){

				elemento.blur();

			}
		}

		if(elemento.querySelector('.ok') === evento.target){
			if(elemento.querySelector('.component')){
				elemento.querySelector('.component').parentElement.classList.remove('active');
				elemento.setAttribute('data-lastevt', 'blur');
			}
		}
	}

	static _lastevt(elemento){

		if(!elemento.getAttribute('data-lastevt')){
			elemento.setAttribute('data-lastevt', 'blur');
		}
	}

	static _debounce(elemento, evento){

		Focustoggle._click(elemento, evento);
		Focustoggle._abrir(elemento, evento);
		Focustoggle._sair(elemento, evento);

	}

	debounce(elemento, evento){

		if(evento.type == 'click'){

			Focustoggle._debounce(elemento, evento);

		}else if(evento.type == 'blur' || evento.type == 'focus'){

			Boss.delayPersistent2(function(){
				Focustoggle._debounce(elemento, evento);
			}, 400, 'datats_' + elemento.id);
		}
	}

	init(){

		/* Não mecha daqui para baixo. */
		Focustoggle._lastevt(this.elemento);

		Boss.evts.add('click', this.elemento, evento => this.debounce(this.elemento, evento));
		Boss.evts.add('blur', this.elemento, evento => this.debounce(this.elemento, evento));
		Boss.evts.add('focus', this.elemento, evento => this.debounce(this.elemento, evento));
	}
}